import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const images = [
  {
    group: [
      {
        cover:
          '/img/ztm/01.png',
        coverDark:
          '/img/ztm/01.png',
      },
      {
        cover:
          '/img/ztm/02.png',
        coverDark:
          '/img/ztm/02.png',
      },
      {
        cover:
          '/img/ztm/03.png',
        coverDark:
          '/img/ztm/03.png',
      },
    ],
  },
  {
    group: [
      {
        cover:
          '/img/ztm/02.png',
        coverDark:
          '/img/ztm/02.png',
      },
      {
        cover:
          '/img/ztm/03.png',
        coverDark:
          '/img/ztm/03.png',
      },
      {
        cover:
          '/img/ztm/04.png',
        coverDark:
          '/img/ztm/04.png',
      },
      {
        cover:
          '/img/ztm/01.png',
        coverDark:
          '/img/ztm/01.png',
      },
    ],
  },
  {
    group: [
      {
        cover:
          '/img/ztm/03.png',
        coverDark:
          '/img/ztm/03.png',
      },
      {
        cover:
          '/img/ztm/04.png',
        coverDark:
          '/img/ztm/04.png',
      },
      {
        cover:
          '/img/ztm/01.png',
        coverDark:
          '/img/ztm/01.png',
      },
      {
        cover:
          '/img/ztm/02.png',
        coverDark:
          '/img/ztm/02.png',
      },
      {
        cover:
          '/img/ztm/03.png',
        coverDark:
          '/img/ztm/03.png',
      },
    ],
  },
];


// ZTM HomeConnect, powered by the open-source ZTM project, enables you to securely connect to your personal files, media, and devices without any geographical or network restrictions. Whether you're traveling for work and need to access important files from your home computer, or you want to share cherished photos with friends and family without relying on big-tech social networks, ZTM HomeConnect provides the seamless connectivity you need.
// With ZTM HomeConnect, you can:
// * Access your home network from anywhere: Securely connect to your home computer or NAS (Network Attached Storage) from across the globe, whether you're on a business trip or vacation.
// * Share files privately: Easily share documents, pictures, and videos within your family and friends, bypassing the need for commercial social platforms that might compromise your privacy.
// * Private, secure communication: Host peer-to-peer (P2P) chats with the confidence that your conversations are protected from eavesdropping.
// ZTM HomeConnect leverages the core capabilities of ZTM, including encrypted communication channels, certificate-based authentication, and robust service discovery. Stay connected and in control, no matter where you are, with the reliable access that ZTM HomeConnect delivers.
const mock = [
  {
    title: 'ZTM.',
		logo:['/img/ztm.png','/img/ztm.png'],
    desc: [
			`<div>
<b>ZTM HomeConnect</b>, powered by the open-source <a href="https://github.com/flomesh-io/ztm" target="_blank">ZTM</a> project, enables you to securely connect to your personal files, media, and devices without any geographical or network restrictions. Whether you're traveling for work and need to access important files from your home computer, or you want to share cherished photos with friends and family without relying on big-tech social networks, <b>ZTM HomeConnect</b> provides the seamless connectivity you need.
With <b>ZTM HomeConnect</b>, you can:
<ul><li> Access your home network from anywhere.</li>
<li> Share files privately.</li>
<li> Private, secure communication.</li>
</ul></div>
<b>ZTM HomeConnect</b> leverages the core capabilities of <b>ZTM</b>, including encrypted communication channels, certificate-based authentication, and robust service discovery. Stay connected and in control, no matter where you are, with the reliable access that <b>ZTM HomeConnect</b> delivers.
`,
			// '<b>Flomesh GUI</b> is an all-in-one console utilizing the <b>DB/Strapi/Vue</b> three-tier architecture. It consists of modules for service mesh, software load balancing, API management, health check, operation and maintenance center, etc. It also provides basic system management functionality.',
			// 'The product supports <b>multi-tenant</b>, <b>multi-cluster</b> and <b>multi-registry</b> service traffic management, provides comprehensive observability and a unified view for services. With its API management, plenty of out-of-the-box pluggable policies are ready for use.'
		],
		btn:'ZTM Home',
		path:'/ztm',
		prefix: '',
		words: [
			'Zero Trust Mesh', 
			'Peer to Peer',
			'Open Source',
			'Network Attached Storage'
		]
  }
];
const Hero = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
		  sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0,
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box sx={{ overflow: 'hidden' }} paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
					{mock.map((item, i) => (
						<Box sx={{ display: 'flex' }} key={i}>
							<Box maxWidth={{ xs: 1, sm: '50%' }} sx={{
								minHeight: '250px'
							}}>
								<Typography
									variant="h3"
									color="text.primary"
									gutterBottom
									sx={{
										fontWeight: 700,
									}}
								>
									{ item.logo && (
										<Box
											display={'inline-block'}
											component={'span'}
											height={{ xs: 70, md: 70 }}
											sx={{
												verticalAlign: 'middle',
												position: 'relative',
												marginRight: '20px'
											}}
										>
											<Box
												component={'img'}
												sx={{
													verticalAlign: 'middle'
												}}
												src={
													mode === 'light'
														? item.logo[0]
														: item.logo[1]
												}
												height={1}
											/>
										</Box>
									)}
									<Box
										display={'inline-block'}
										sx={{
											verticalAlign: 'middle'
										}}
									>
										{item.title}
									</Box>
									<br />
									{item.prefix?(
										<Typography
											color="text.primary"
											component={'span'}
											variant="h5"
											sx={{
												marginRight: '10px',
											}}
										>
										{item.prefix}
										</Typography>
									):null}
									{item.words?(
										<Typography
											color={'primary'}
											component={'span'}
											variant="h5"
										>
											<Typed
												strings={item.words}
												typeSpeed={80}
												loop={true}
											/>
										</Typography>
									):null}
								</Typography>
								{item.desc.map((desc, j) => (
									<Typography
										key={j}
										variant="h7"
										component="div"
										color="text.secondary"
										sx={{ fontWeight: 400,marginBottom:'15px' }}
										dangerouslySetInnerHTML={{__html:desc}}
									>
									</Typography>
								))}
								
								<Box
								  display="flex"
								  flexDirection={{ xs: 'column', sm: 'row' }}
								  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
								  marginTop={4}
								>
									<Button
								    component="a"
										variant="contained"
										color="primary"
										size="large"
										target="_blank"
										fullWidth={isMd ? false : true}
								    href="https://github.com/flomesh-io/ztm/wiki/ZTM-App-Introduction-and-QuickStart"
									>
										Quick start
									</Button>
								  <Box
								    marginTop={{ xs: 2, sm: 0 }}
								    marginLeft={{ sm: 2 }}
								    width={{ xs: '100%', md: 'auto' }}
								  >
										<Button
											component="a"
											href="https://github.com/flomesh-io/ztm"
											variant="outlined"
											color="primary"
											target="_blank"
											size="large"
											fullWidth={isMd ? false : true}
										>
											Github
										</Button>
								  </Box>
								</Box>
								
								
							</Box>
							
							<Box
								padding={4}
								maxWidth={{ xs: 1, sm: '48%' }} 
								sx={{
									minHeight: '250px'
								}}
							>
							<Box
								padding={1}
								bgcolor={'background.paper'}
								borderRadius={2}
								boxShadow={3}
								marginTop={2}
							>
							<iframe width="560" height="315" src="https://www.youtube.com/embed/Nx6-297K4AU?si=NROuZ6G84WT_qV6v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
								
							</Box>
						</Box>
						</Box>
					))}
					
        </Container>
				
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
					<Box
						sx={{
							transform: 'rotate(-20deg)',
							display: { xs: 'none', sm: 'block' },
						}}
					>
					
						<Box
						
							display={'none'}
							width={'50rem'}
							left={'50%'}
							top={0}
							position={'absolute'}
							sx={{ transform: 'translate3d(30%, -100%, 0)' }}
						>
							{images.map((item, i) => (
								<Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
									{item.group.map((g, j) => (
										<Box
											key={j}
											padding={1}
											bgcolor={'background.paper'}
											borderRadius={2}
											boxShadow={3}
											marginTop={2}
										>
											<Box
												component={LazyLoadImage}
												effect="blur"
												src={
													theme.palette.mode === 'dark' ? g.coverDark : g.cover
												}
												height={1}
												width={1}
												maxWidth={320}
											/>
										</Box>
									))}
								</Box>
							))}
						</Box>
					</Box>
        </Box>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
