/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const mock = [
	{ 
		title:`Access your home network from anywhere`,
		primary:'',
		desc:`Securely connect to your home computer or NAS (Network Attached Storage) from across the globe, whether you're on a business trip or vacation.`,
		feature:'/img/ztm/pc2.png',
		layout:'left'
	},
	{ 
		title:`Share files privately`,
		primary:'',
		desc:`Easily share documents, pictures, and videos within your family and friends, bypassing the need for commercial social platforms that might compromise your privacy.`,
		feature:'/img/ztm/pc3.png',
		layout:'right'
	},
	{ 
		title:`Private, secure communication`,
		primary:'',
		desc:`Host peer-to-peer (P2P) chats with the confidence that your conversations are protected from eavesdropping.`,
		feature:'/img/ztm/pc6.png',
		layout:'left'
	},
]
const Teaser = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
	  {mock.map((item, i) => (
			<Box key={i}>
			{( item.layout=='left'||!isMd )
			? <Grid  container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-right' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'} dangerouslySetInnerHTML={{__html:item.desc}}>
					</Typography>
				  </Box>
				</Grid>
				<Grid
				  item
				  container
				  justifyContent="center"
				  alignItems="center"
				  xs={12}
				  md={6}
				>
				  <Box maxWidth={490} width={1}>
					<Box
						padding={1}
						bgcolor={'background.paper'}
						borderRadius={2}
						boxShadow={3}
						marginTop={2}
					>
						<Box
							component={LazyLoadImage}
							effect="blur"
							src={item.feature}
							height={1}
							width={1}
							maxWidth={490}
							maxHeight={690}
						/>
					</Box>
				  </Box>
				</Grid>
			  </Grid>
			: <Grid key={i} container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
					item
					container
					justifyContent="center"
					alignItems="center"
					xs={12}
					md={6}
				  >
					<Box maxWidth={490} width={1}>
						<Box
							padding={1}
							bgcolor={'background.paper'}
							borderRadius={2}
							boxShadow={3}
							marginTop={2}
						>
							<Box
								component={LazyLoadImage}
								effect="blur"
								src={item.feature}
								height={1}
								width={1}
								maxWidth={490}
								maxHeight={690}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-left' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'} dangerouslySetInnerHTML={{__html:item.desc}}>
					</Typography>
				  </Box>
				</Grid>
			  </Grid>
				}
				</Box>
      ))}
    </Box>
  );
};

export default Teaser;
